<!-- 咨询 -->
<template>
	<div id="Information">
		<van-nav-bar :title="$t('m_home.zx')" left-arrow @click-left="onClickLeft">
		</van-nav-bar>
		<!-- 内容 -->
		<div class="warp">
			<div class="InformationList">
				<ul>
					<li @click="readFun(item)" v-for="(item, index) in list" :key="index">
						<div class="img">
							<img :src="configUrl + item.img" lazy="loaded" />
						</div>
						<div class="text">
							<p class="title">{{ item.title }}</p>
							<p class="time">{{ item.create_time.substring(0, 10) }}</p>
						</div>
					</li>
				</ul>
			</div>
			<div class="Nodata" v-if="list.length <= 0">
				<div>
					<img src="../../../assets/img/notPage.png" alt="" />
					<p>{{ $t("m_fundsSummary.zwsj") }}</p>
				</div>
			</div>
		</div>
		<!-- 详情 -->
		<van-popup class="popup-msg" v-model="showMsg" position="bottom" closeable close-icon-position="top-right"
			:style="{ height: '100%' }">
			<!-- 头部 -->
			<div class="scroll-header">
				<div class="title">{{ $t("m_Information.zxxq") }}</div>
			</div>
			<h1>{{ currentMsg.title }}</h1>
			<!-- <p class="time">
				{{ $t("message.fbsj") }}：{{ currentMsg.create_time.substring(0, 10) }}
			</p> -->
			<p v-html="currentMsg.content"></p>
			<!-- <p>{{ currentMsg.content }}</p> -->
		</van-popup>
	</div>
</template>

<script>
	import {
		getNews
	} from "@/api/home";
	import {
		Toast
	} from "vant";
	export default {
		name: "",
		data() {
			return {
				list: [],
				showMsg: false,
				configUrl: process.env.VUE_APP_BASE_API,
				currentMsg: {
					title: "--",
					create_time: "--",
					content: "--",
				},
			};
		},
		//生命周期 - 创建完成（访问当前this实例）
		created() {},
		//生命周期 - 挂载完成（访问DOM元素）
		mounted() {
			this.getNewsShow();
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1);
			},
			loadingCom() {
				// 自定义加载图标
				const toast = Toast.loading({
					message: this.$t("common.loading"),
					forbidClick: true,
					loadingType: "spinner",
					duration: 60000,
				});
			},
			readFun(item) {
				var richtext = item.content;
				const regex = new RegExp('<img', 'gi');
				item.content = richtext.replace(/(\<img|\<p)/gi, function($0, $1) {
					return {
						"<img": '<img style="width:100%;height:auto;display:block;" ',
						"<p": '<p ',
						"<article": "<div",
						"</article": "</div",
						"<header": "<div",
						"</header": "</div"
					} [$1];
				});
				this.currentMsg = item;
				this.showMsg = true;
			},
			getNewsShow() {
				this.loadingCom();
				getNews().then((res) => {
					console.log(res);
					Toast.clear();
					this.list = res.data;
				});
			},
		},
	};
</script>
<style scoped lang="less">
	@import "../../../assets/css/mobile.less";

	#Information {
		width: 100%;
		min-height: 100vh;
		background: #404040 url(../../../assets/img/mcbg.png) no-repeat top;
		background-size: contain;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;

		.warp {
			width: 100%;
			padding: 0.25rem;
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			box-sizing: border-box;
		}
	}

	#Information .Nodata {
		flex: 1;
		width: 100%;
		height: 100%;
		min-height: 5rem;
		position: relative;

		div {
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			width: 5rem;
		}

		img {
			width: 5rem;
		}

		p {
			width: 100%;
			text-align: center;
			color: #fff;
			font-size: 0.28rem;
		}
	}

	#Information .warp {
		width: 100%;
		padding: 0.25rem;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;

		.InformationList {
			width: 100%;
			margin-top: 0.2rem;
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			box-sizing: border-box;

			ul li {
				height: 2.2rem;
				background-color: #373430;
				border-radius: 0.2rem;
				padding: 0.2rem 0.25rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 0.2rem;
				box-sizing: border-box;

				.img {
					width: 3.5rem;
					height: 1.8rem;
					background-color: #000000;
					box-sizing: border-box;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.text {
					display: flex;
					min-height: 1.7rem;
					flex-direction: column;

					.time {
						-webkit-box-flex: 1;
						-ms-flex: 1;
						flex: 1;
						color: #ffffff;
						font-size: 0.24rem;
						text-align: right;
						display: flex;
						align-items: flex-end;
						justify-content: flex-end;
					}
				}

				.title {
					color: #eddbc1;
					font-size: 0.32rem;
					font-family: PingFangSC-Semibold;
					line-height: 0.4rem;
					max-width: 2.5rem;
					max-height: 0.8rem;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					-webkit-line-clamp: 2;
					word-wrap: break-word;
				}
			}
		}
	}

	.popup-msg {
		box-sizing: border-box;
		background-color: #222;

		h1 {
			width: 100%;
			font-size: 0.36rem;
			color: #b7b7b7;
			font-weight: 400;
			text-align: center;
			padding: 0.2rem;
			box-sizing: border-box;
		}

		.time {
			text-align: center;
			width: 90%;
			color: #b4b4b4;
			padding: 0 5% 0.5rem;
			box-sizing: border-box;
			border-bottom: 1px solid #333333;
		}

		p {
			width: 90%;
			margin: 0.2rem auto;
			font-size: 0.26rem;
			line-height: 0.4rem;
			word-wrap: break-word;
			word-break: normal;
			font-size: 0.28rem;
			color: #b7b7b7;
			box-sizing: border-box;
		}
	}

	.scroll-header {
		height: 40px;
		line-height: 40px;
		background-image: linear-gradient(0deg, #ceab77 20%, #eddbc1 100%);
		color: #000;
		display: flex;
		justify-content: center;
		font-size: 0.3375rem;
	}

	/deep/.van-popup__close-icon--top-right {
		top: 0.15rem;
		right: 16px;
	}

	/deep/.van-popup__close-icon {
		position: absolute;
		z-index: 1;
		color: #fff;
		font-size: 20px;
		cursor: pointer;
	}

	.van-action-sheet__item,
	.van-action-sheet__cancel {
		color: #000;
		background-color: #ffffff;
	}
</style>
