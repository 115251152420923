import request from '@/utils/request'
/* 产品介绍 */
export function introduction(params) {
    console.log(params)
    return request({
        url: 'api/index/config',
        method: 'GET'
    })
}

/* 首页轮播图 */
export function bannerShow(params) {
    console.log(params)
    return request({
        url: `api/index/slide?cate=${params.cate}`,
        method: 'GET'
    })
}

/* 兑率 */
export function getRate() {
    return request({
        url: `api/index/rate`,
        method: 'GET'
    })
}

/* 资讯 */
export function getNews() {
    return request({
        url: `api/index/news`,
        method: 'GET'
    })
}

/* 交易动态 */
export function getList() {
    return request({
        url: `api/index/list`,
        method: 'GET'
    })
}

